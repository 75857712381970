import React from "react";
import { LinkButton } from "src/design-system/atoms/button";
import vars from "src/design-system/variables";
import DataRow from "src/design-system/components/data-row/data-row";
import { formatDate } from "src/utils/dateHelpers";
import useScorecardDownload from "src/design-system/atoms/useScorecardDownload";

const BenchmarkWholeCompanyDataRow = ({ className, data }) => {
  const scorecards = data["whole-company"].scorecardUrls;
  const { hasScorecards, downloadScorecard, latestScorecard } =
    useScorecardDownload(scorecards);

  return (
    <DataRow
      className={className}
      columnWidths={vars.benchmarkSurveyColumnWidths}
    >
      <div>{data.responseId}</div>
      <div>{data.companyName}</div>
      <div>{formatDate(data.recordedDate)}</div>
      <div>{formatDate(data.dateOfAnalystReview)}</div>
      <div>
        {data.financialYear} / v{data.circulyticsJsonSchemaVersion.slice(0, 3)}
      </div>
      <a
        href={data.serviceOutputData.final_data}
        target="_blank"
        rel="noreferrer"
      >
        View Data
      </a>
      <LinkButton
        isDisabled={!hasScorecards}
        onClick={downloadScorecard}
        href={latestScorecard}
      >
        Download
      </LinkButton>
    </DataRow>
  );
};

export default BenchmarkWholeCompanyDataRow;
