import React from "react";
import { withAuthenticator } from "@aws-amplify/ui-react";
import Layout from "src/design-system/layouts/layout";
import BenchmarkSurveyList from "src/design-system/blocks/benchmark/benchmark-survey-list/benchmark-survey-list";
import PageNav from "src/design-system/components/primary-navigation/circulytics-page-navigation";

const BenchmarksPage = () => {
  return (
    <Layout>
      <main>
        <title>Benchmarks | Circulytics</title>
        <PageNav />
        <h1>Benchmarks</h1>
        <p>
          The new survey responses that have been approved for benchmarking
          since the last benchmark will appear here. <br />
          When the "Calculate new benchmarks" button is clicked, a new set of
          benchmarks will be created using the surveys listed here <br />
          including all previously approved surveys.
          <br />
          <br />
        </p>

        <BenchmarkSurveyList />
      </main>
    </Layout>
  );
};

export default withAuthenticator(BenchmarksPage);
