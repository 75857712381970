import React from "react";
import styled from "@emotion/styled";

import vars from "src/design-system/variables";
import Button from "src/design-system/atoms/button";

const BenchmarkControl = ({ className, onReset }) => {
  return (
    <div className={className}>
      <Button onClick={onReset} color={vars.circulyticsRed}>
        Unreview
      </Button>
    </div>
  );
};

const StyledBenchmarkControl = styled(BenchmarkControl)`
  display: flex;
  width: 100%;
  margin-left: 1rem;
  button {
    margin-right: 0.25rem;
  }
`;

export default StyledBenchmarkControl;
