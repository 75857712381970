import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import styled from "@emotion/styled/macro";
import vars from "src/design-system/variables";

import SurveyList from "src/design-system/components/survey-list/survey-list";
import BenchmarkSurveyHeader from "src/design-system/components/benchmark/benchmark-survey-header/benchmark-survey-header";
import BenchmarkSurveyRow from "src/design-system/components/benchmark/benchmark-survey-row/benchmark-survey-row";
import LoadingSpinner from "src/design-system/atoms/loading-spinner";
import LoadingRow from "src/design-system/components/loading-row/loading-row";
import Button from "src/design-system/atoms/button";
import Status from "src/design-system/atoms/status";

const BenchmarkSurveyList = ({ className }) => {
  const [status, setStatus] = useState({ type: "", message: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [surveys, setSurveys] = useState([]);
  const [pageKey, setPageKey] = useState(null);
  const [hasMoreSurveys, setHasMoreSurveys] = useState(false);

  const surveyLimit = 30;

  const getApiData = async () => {
    setIsLoading(true);
    let atLimit = false;
    let tmpSurveys = [...surveys];
    let tmpPageKey = pageKey;
    while (!atLimit) {
      const params = { limit: surveyLimit };
      if (tmpPageKey) {
        params.last_evaluated_key = JSON.stringify(tmpPageKey);
      }
      await API.get("circulytics-surveys-benchmark-pending", "/", {
        queryStringParameters: params,
      }).then((res) => {
        tmpSurveys = tmpSurveys.concat(res.surveys);
        tmpPageKey = res.last_evaluated_key;
        atLimit = tmpSurveys.length >= surveyLimit || tmpPageKey === null;
      });
    }
    setSurveys([...tmpSurveys]);
    setPageKey(tmpPageKey);
    setHasMoreSurveys(tmpPageKey === null);
    setIsLoading(false);
  };
  const triggerBenchmarksAPI = () => {
    setStatus({ type: "success", message: "Running benchmarks...please wait" });
    setIsRunning(true);
    API.post("circulytics-benchmarks-run", "/", {})
      .then((res) => {
        setStatus({
          type: "success",
          message: "The new benchmarks have been created",
        });
        setIsRunning(false);
      })
      .catch((err) => {
        setStatus({ type: "error", message: err.response.data.message });
        setIsRunning(false);
      });
  };

  const triggerBenchmarksHandler = () => {
    triggerBenchmarksAPI();
  };

  useEffect(() => {
    getApiData();
  }, []);

  const loadMore = () => {
    if (pageKey) {
      getApiData();
    }
  };

  return (
    <LoadingRow isLoading={isRunning}>
      <div className={className}>
        <SurveyList
          surveys={surveys}
          isLoading={isLoading}
          hasMoreSurveys={hasMoreSurveys}
          SurveyHeader={BenchmarkSurveyHeader}
          SurveyItem={BenchmarkSurveyRow}
          loadMoreHandle={loadMore}
        />
        <div className="survey-control">
          <Status status={status} />
          <Button
            onClick={triggerBenchmarksHandler}
            color={vars.barrowWightGrey}
            backgroundColor={vars.learningGreen}
          >
            {isRunning ? (
              <LoadingSpinner />
            ) : (
              <span>Calculate new benchmarks</span>
            )}
          </Button>
        </div>
      </div>
    </LoadingRow>
  );
};

const StyledBenchmarkSurveyList = styled(BenchmarkSurveyList)`
  .survey-control {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 350px;
    margin-top: 1rem;
    ${Button} {
      padding: 1rem;
    }
  }
`;

export default StyledBenchmarkSurveyList;
