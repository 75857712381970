import React, { useState } from "react";
import { API } from "aws-amplify";
import styled from "@emotion/styled/macro";
import LoadingRow from "src/design-system/components/loading-row/loading-row";
import BenchmarkWholeCompanyDataRow from "src/design-system/components/benchmark/benchmark-wholecompany-data-row/benchmark-wholecompany-data-row";
import BenchmarkControl from "src/design-system/components/benchmark/benchmark-control/benchmark-control";
import Status from "src/design-system/atoms/status";

const BenchmarkSurveyRow = ({ className, data }) => {
  const [rowData, setRowData] = useState(data);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState({ status: "", message: "" });

  const resetAPI = async () => {
    setIsLoading(true);
    const payload = {
      responseId: rowData.responseId,
      businessEntityId: rowData["whole-company"].businessEntityId,
      isReviewed: false,
    };
    await API.post("circulytics-surveys", "/", { body: payload });
  };
  const resetHandler = () => {
    resetAPI()
      .then(() => {
        setRowData({ ...rowData, isReviewed: false });
        setStatus({ type: "success", message: "Unreviewed survey" });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response) {
          const errMessage =
            err.response.data.response ?? err.response.data.error;
          console.log(errMessage);
          setStatus({ type: "error", message: errMessage });
        } else {
          console.log(err);
        }
      });
  };
  return (
    <>
      {rowData.isReviewed === false ? (
        <div>
          {rowData.companyName} ({rowData.responseId}) has been unreviewed - it
          will now appear on the review page again.
        </div>
      ) : (
        <div className={className}>
          <div className="control-wrap">
            <LoadingRow isLoading={isLoading}>
              <BenchmarkWholeCompanyDataRow data={rowData} />
            </LoadingRow>
            <BenchmarkControl onReset={resetHandler} />
          </div>
          <Status status={status} />
        </div>
      )}
    </>
  );
};

const StyledBenchmarkSurveyRow = styled(BenchmarkSurveyRow)`
  .control-wrap {
    display: flex;
    justify-content: space-between;
    padding-right: 1rem;
  }
`;

export default StyledBenchmarkSurveyRow;
