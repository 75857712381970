import React from "react";
import vars from "src/design-system/variables";
import DataRow from "src/design-system/components/data-row/data-row";

const BenchmarkSurveyHeader = () => {
  return (
    <DataRow isHeader columnWidths={vars.benchmarkSurveyColumnWidths}>
      <div>Response ID</div>
      <div>Company Name</div>
      <div>Submission Date</div>
      <div>Review Date</div>
      <div>FY / Version</div>
      <div>Final Data</div>
      <div>Scorecard</div>
    </DataRow>
  );
};

export default BenchmarkSurveyHeader;
